
import {
  Component, Prop, Vue, Provide,
} from 'nuxt-property-decorator'
import { Section, RichTextElement } from 'fsxa-api'
import { TContentProjection } from '../shared/general/types/TContentProjection'
import EButtonVariant from '../shared/general/enums/EButtonVariant'
import { globalLabelAsString } from '../shared/general/services/StoreService'

@Component({
  name: 'ContentProjection',
  components: {
    FSXARenderContentElement: async () => (await import('fsxa-pattern-library')).FSXARenderContentElement,
    BaseGridLayout: () => import('./layouts/BaseGridLayout.vue'),
    BaseHeadline: () => import('./base/BaseHeadline.vue'),
    SeparationLine: () => import('./SeparationLine.vue'),
    EventHeader: () => import('./EventHeader.vue'),
    BaseButton: () => import('./base/BaseButton.vue'),
    GlobalLabelWrapper: () => import('./GlobalLabelWrapper.vue'),
  },
})
export default class ContentProjection extends Vue {
  @Prop({ required: true }) type! : TContentProjection

  @Prop({ default: false }) showInThisLanguage! : boolean

  @Prop() getHomepageUrl! : Function

  @Prop() headline ?: string

  @Prop() location ?: string

  @Prop() date ?: string

  @Prop() author ?: string

  @Prop() overline ?: string

  @Prop({ default: () => [] }) pageContent! : Section[]

  @Prop() overviewPageLabel ?: string

  @Prop() overviewPageLink ?: string

  @Prop({ default: () => [] }) tags! : string[]

  @Provide('separationLineUsedInContentProjection') separationLineUsedInContentProjection : boolean = true

  @Provide('carouselUsedInContentProjection') carouselUsedInContentProjection : boolean = true

  @Provide('infoboxUsedInContentProjection') infoboxUsedInContentProjection : boolean = true

  private homepageLink : string = ''

  async mounted () {
    this.homepageLink = await this.getHomepageUrl()
  }

  private get marginTopContent () : string {
    return this.type === 'event' ? '!mt-4' : '!mt-2'
  }

  private get outlineButtonVariant () : EButtonVariant {
    return EButtonVariant.Outline
  }

  private get authorLabel () : string | RichTextElement[] {
    return globalLabelAsString('author_label')
  }

  private get notTranslatedLabel () : string {
    return globalLabelAsString('not_translated_error_label')
  }

  private get toHomepageLabel () : string {
    return globalLabelAsString('go_to_homepage_label')
  }

  private get backToPreviousPageLabel () : string {
    return globalLabelAsString('back_to_previous_page_label')
  }

  private goBack () : void {
    this.$router.go(-1)
  }
}
