
import { Component, mixins, Watch } from 'nuxt-property-decorator'
import { RichTextElement, Section } from 'fsxa-api'
import { FSXARenderContentElement } from 'fsxa-pattern-library'
import BasePage from '../base/BasePage.vue'
import BaseGridLayout from '../../layouts/BaseGridLayout.vue'
import BaseServicePage from '../base/BaseServicePage.vue'
import BaseToolbarWrapper from '../../base/toolbar/BaseToolbarWrapper.vue'
import TeaserRcWrapper from '../../teaser/rc/TeaserRcWrapper.vue'
import { globalLabel } from '../../../shared/general/services/StoreService'
import BaseHeadline from '../../base/BaseHeadline.vue'
import MessageBar from '../../MessageBar.vue'
import GlobalLabelWrapper from '../../GlobalLabelWrapper.vue'
import getOrFetchRemoteDatasets, { getRemoteDatasetsFromStore } from '../../../shared/fsxa/services/RemoteDatasetService'
import { TRemoteDatasetIndex } from '../../../shared/fsxa/types/TRemoteDataset'
import IServiceData from '../../../shared/fsxa/interfaces/IServiceData'

@Component({
  name: 'PtServicePage',
  components: {
    BaseGridLayout,
    BaseToolbarWrapper,
    TeaserRcWrapper,
    FSXARenderContentElement,
    BaseHeadline,
    MessageBar,
    GlobalLabelWrapper,
  },
})
export default class PtServicePage extends mixins(BasePage, BaseServicePage) {
  created () {
    this.$store.commit('Page/setPageType', 'ServicePage')
  }

  async mounted () {
    await this.fetchRemoteDatasetsServicePage()
  }

  async serverPrefetch () {
    await this.fetchRemoteDatasetsServicePage()
  }

  private async fetchRemoteDatasetsServicePage () : Promise<void> {
    await getOrFetchRemoteDatasets(this.servicePageRemoteDatasetIndex)
  }

  private get servicePageRemoteDatasetIndex () : TRemoteDatasetIndex | undefined {
    return this.data.pt_service_remote
  }

  private get servicePageServiceRemote () : IServiceData | undefined {
    return getRemoteDatasetsFromStore(this.servicePageRemoteDatasetIndex)[0]
  }

  private get showServiceContent () : boolean {
    return this.data.pt_show_service_content === undefined ? true : this.data.pt_show_service_content
  }

  private get showInThisLanguage () : boolean {
    // prevent showing untranslated notification if remote dataset is not yet loaded
    if (!this.servicePageServiceRemote) return true
    return !!this.servicePageServiceRemote.data?.tt_show_in_this_language
  }

  private get serviceContent () : Section[] {
    return this.servicePageServiceRemote?.data?.tt_page_content || []
  }

  private get showHeaderContent () : boolean {
    return !!this.headerContent.length
  }

  private get headerContent () : Section[] {
    return this.servicePageServiceRemote?.data?.tt_elements_for_header_content || []
  }

  private get contentNotTranslatedLabel () : string| RichTextElement[] {
    return globalLabel('content_not_translated_label')
  }

  @Watch('$store.state.Locations.savedLocations', { deep: true, immediate: true })
  private async savedLocationsChange () : Promise<void> {
    this.$store.commit('LocationOnlineAppointment/set', await this.getOnlineAppointmentLink())
    this.$store.commit('ToolbarElements/setOnlineAppointmentLink', await this.getOnlineAppointmentLink(true))
  }
}
