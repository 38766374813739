
import { Component } from 'nuxt-property-decorator'
import { FSXABaseSection } from 'fsxa-pattern-library'
import { ILegalEntity } from '../../../shared/general/interfaces/ILegalEntity'
import getOrFetchRemoteDatasets, { getRemoteDatasetsFromStore } from '../../../shared/fsxa/services/RemoteDatasetService'
import { TRemoteDatasetIndex } from '../../../shared/fsxa/types/TRemoteDataset'

@Component({
  name: 'StLegalEntityList',
  components: {
    LegalEntityList: () => import('../../LegalEntityList.vue'),
  },
})
export default class StLegalEntityList extends FSXABaseSection<{}> {
  private data : ILegalEntity[] = []

  async mounted () {
    await this.fetchRemoteDatasets()
    if (this.countryKeyRemote?.length) await this.fetchData()
  }

  async serverPrefetch () {
    await this.fetchRemoteDatasets()
  }

  private async fetchRemoteDatasets () : Promise<void> {
    await getOrFetchRemoteDatasets(this.countryRemoteDatasetIndex)
  }

  async fetchData () : Promise<void> {
    try {
      this.data = await (await fetch(`${this.host}/api/legalEntities/${this.countryKeyRemote}/`)).json()
    } catch (error) {
      this.data = []
    }
  }

  private get host () : string {
    return process.client ? window.location.origin : 'http://localhost:3000'
  }

  private get countryRemoteDatasetIndex () : TRemoteDatasetIndex | undefined {
    return this.globalSettings?.data?.ps_country_remote
  }

  private get countryKeyRemote () : string | undefined {
    return getRemoteDatasetsFromStore(this.countryRemoteDatasetIndex)[0]?.data?.tt_key
  }
}
