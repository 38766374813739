
import { Component } from 'nuxt-property-decorator'
import { Section } from 'fsxa-api'
import { FSXABaseSection } from 'fsxa-pattern-library'
import IContact from '../../../shared/general/interfaces/IContact'
import ILocationData from '../../../shared/fsxa/interfaces/ILocationData'
import createContactArray from '../../../shared/fsxa/services/ContactView'
import IContactElement from '../../../shared/general/interfaces/IContactElement'
import { TRemoteDatasetIndex } from '../../../shared/fsxa/types/TRemoteDataset'
import getOrFetchRemoteDatasets, { getRemoteDatasetsFromStore } from '../../../shared/fsxa/services/RemoteDatasetService'
import { TLocationSectionView } from '../../../shared/fsxa/types/TView'
import { TContactElement } from '../../../shared/general/types/TContactElement'

interface IPayload {
  st_headline ?: string
  st_location_remote ?: TRemoteDatasetIndex
  st_location_view_remote ?: TRemoteDatasetIndex
  st_column ?: {
    identifier ?: '1' | '3'
  }
}

enum COLUMN {
  Left = 'left',
  Middle = 'mid',
  Right = 'right'
}

@Component({
  name: 'StLocation',
  components: { ContactElement: () => import('../../contact/ContactElement.vue') },
})
export default class StLocation extends FSXABaseSection<IPayload> {
  async mounted () {
    await this.fetchRemoteDatasets()
  }

  async serverPrefetch () {
    await this.fetchRemoteDatasets()
  }

  async fetchRemoteDatasets () : Promise<void> {
    await Promise.all([
      getOrFetchRemoteDatasets(this.locationRemoteDatasetIndex),
      getOrFetchRemoteDatasets(this.locationViewRemoteDatasetIndex),
    ])
  }

  private get locationRemoteDatasetIndex () : TRemoteDatasetIndex | undefined {
    return this.payload.st_location_remote
  }

  private get locationViewRemoteDatasetIndex () : TRemoteDatasetIndex | undefined {
    return this.payload.st_location_view_remote
  }

  private get locationRemote () : ILocationData[] {
    return getRemoteDatasetsFromStore(this.locationRemoteDatasetIndex)
  }

  private get locationViewRemote () : TLocationSectionView[] {
    return getRemoteDatasetsFromStore(this.locationViewRemoteDatasetIndex)
  }

  private get locationViewConfigLeft () : Section[] {
    return this.locationViewRemote[0]?.data?.tt_config_left || []
  }

  private get locationViewConfigMid () : Section[] {
    return this.locationViewRemote[0]?.data?.tt_config_mid || []
  }

  private get locationViewConfigRight () : Section[] {
    return this.locationViewRemote[0]?.data?.tt_config_right || []
  }

  private getContent (col : COLUMN, locationData : ILocationData) : IContactElement[] {
    let locationView : Section[] = []
    switch (col) {
      case COLUMN.Left:
        locationView = this.locationViewConfigLeft
        break
      case COLUMN.Middle:
        locationView = this.locationViewConfigMid
        break
      case COLUMN.Right:
        locationView = this.locationViewConfigRight
        break
      default:
        locationView = []
    }
    if (!locationView.length || !locationData?.data) return []

    return createContactArray(locationData, locationView, this.getUrlByPageId, col)
      .filter((el : IContactElement) => el.data && el.type !== 'empty')
  }

  private get locationInformation () : TContactElement {
    return {
      headline: this.payload.st_headline,
      size: this.payload.st_column?.identifier === '1' ? 'small' : 'standard',
      information: this.locationRemote.map((locationData : ILocationData) : IContact => ({
        contentLeft: this.getContent(COLUMN.Left, locationData),
        contentMiddle: this.getContent(COLUMN.Middle, locationData),
        contentRight: this.getContent(COLUMN.Right, locationData),
      })),
    }
  }

  private get anchorName () : string | undefined {
    return this.$store.getters['AnchorLinks/getAnchorNameForSection'](this.id)
  }
}
