
import { FSXABaseSection } from 'fsxa-pattern-library'
import { Component } from 'nuxt-property-decorator'
import { ILocationTypeData, ILocationTypeFilterData } from '../../../shared/fsxa/interfaces/ILocationData'
import { TRemoteDatasetIndex } from '../../../shared/fsxa/types/TRemoteDataset'
import getOrFetchRemoteDatasets, { getRemoteDatasetsFromStore } from '../../../shared/fsxa/services/RemoteDatasetService'
import { TLocationSearchView } from '../../../shared/fsxa/types/TView'
import ITagData from '../../../shared/fsxa/interfaces/ITagData'

interface IPayload {
  st_location_section_view_remote ?: TRemoteDatasetIndex
  st_location_type_for_commercial_vehicles_remote ?: TRemoteDatasetIndex
  st_location_types_remote ?: TRemoteDatasetIndex
  st_locations_without_type ?: boolean
  st_location_tags_remote ?: TRemoteDatasetIndex
}

@Component({
  name: 'StLocationSearch',
  components: {
    LocationSearchWrapper: () => import('../../LocationSearch/LocationSearchWrapper.vue'),
  },
})
export default class StLocationSearch extends FSXABaseSection<IPayload> {
  async mounted () {
    await this.fetchRemoteDatasets()
  }

  async serverPrefetch () {
    await this.fetchRemoteDatasets()
  }

  async fetchRemoteDatasets () : Promise<void> {
    await Promise.all([
      getOrFetchRemoteDatasets(this.locationTagsRemoteDatasetIndex),
      getOrFetchRemoteDatasets(this.locationTypesRemoteDatasetIndex),
      getOrFetchRemoteDatasets(this.locationSectionViewRemoteDatasetIndex),
      getOrFetchRemoteDatasets(this.locationTypeForCommercialVehiclesRemoteDatasetIndex),
    ])
  }

  private get locationTagsRemoteDatasetIndex () : TRemoteDatasetIndex | undefined {
    return this.payload.st_location_tags_remote
  }

  private get locationTypesRemoteDatasetIndex () : TRemoteDatasetIndex | undefined {
    return this.payload.st_location_types_remote
  }

  private get locationSectionViewRemoteDatasetIndex () : TRemoteDatasetIndex | undefined {
    return this.payload.st_location_section_view_remote
  }

  private get locationTypeForCommercialVehiclesRemoteDatasetIndex () : TRemoteDatasetIndex | undefined {
    return this.payload.st_location_type_for_commercial_vehicles_remote
  }

  private get locationTagsRemote () : ITagData[] {
    return getRemoteDatasetsFromStore(this.locationTagsRemoteDatasetIndex)
  }

  private get locationTypesRemote () : ILocationTypeData[] {
    return getRemoteDatasetsFromStore(this.locationTypesRemoteDatasetIndex)
  }

  private get locationSectionViewRemote () : TLocationSearchView | undefined {
    return getRemoteDatasetsFromStore(this.locationSectionViewRemoteDatasetIndex)[0]
  }

  private get locationTypeForCommercialVehiclesRemote () : ILocationTypeData[] {
    return getRemoteDatasetsFromStore(this.locationTypeForCommercialVehiclesRemoteDatasetIndex)
  }

  private get preFilters () : ILocationTypeFilterData[] {
    return this.locationTypesRemote.map((type) => ({
      id: type.id,
      label: type.data.tt_name,
      value: type.data.tt_key,
    }))
  }

  private get hasOnePreFilter () : boolean {
    return this.preFilters.length === 1
  }

  private get getId () : string {
    return this.id || ''
  }

  private get showLocationsWithoutType () : boolean {
    return !!this.payload.st_locations_without_type
  }

  private get locationTypeForCommercialVehicles () : string | undefined {
    return this.locationTypeForCommercialVehiclesRemote[0]?.data?.tt_name?.length
      ? this.locationTypeForCommercialVehiclesRemote[0].data.tt_name
      : undefined
  }

  private get tagFilters () : string[] {
    return this.locationTagsRemote.map((tag) => tag?.data?.tt_name || '').filter((tagName) => !!tagName) || []
  }
}
