
import {
  Component, Emit, Prop, Vue, Watch,
} from 'nuxt-property-decorator'
import IOption from '../../shared/general/interfaces/IOption'

@Component({ name: 'BaseFlyout' })
export default class BaseFlyout extends Vue {
  @Prop() active ?: boolean

  @Prop() ariaLabel ?: string

  @Prop({ default: () => [] }) items! : IOption[]

  @Prop({ required: true }) parentName! : string

  @Prop({ required: true }) selected! : IOption

  private currentIndex = 0

  mounted () {
    document.addEventListener('keyup', this.onKeyUp)
    document.addEventListener('keydown', this.onKeyDown)
  }

  private get currentElement () : IOption {
    return this.items[this.currentIndex]
  }

  destroyed () {
    document.removeEventListener('keyup', this.onKeyUp)
    document.removeEventListener('keydown', this.onKeyDown)
  }

  private onKeyUp (event : KeyboardEvent) : void {
    if (!this.active) return
    event.preventDefault()
    event.stopPropagation()
    const key = event.key.toLowerCase()
    if (key === 'arrowup') this.navigate(-1)
    if (key === 'arrowdown') this.navigate(1)
  }

  private onKeyDown (event : KeyboardEvent) : void {
    if (!this.active) return
    event.preventDefault()
    event.stopPropagation()
  }

  private clamp (value : number, min : number, max : number) : number {
    return Math.min(Math.max(value, min), max)
  }

  private navigate (index : number) : void {
    this.currentIndex = this.clamp(this.currentIndex + index, 0, this.items.length - 1)
    this.focusElement(this.currentElement.value)
  }

  @Watch('active')
  private onActiveChange (active : boolean) : void {
    if (!active) return
    setTimeout(() => {
      this.currentIndex = this.items.findIndex((item) => item.value === this.selected.value)
      this.focusElement(this.selected.value)
    }, 200)
  }

  @Emit('select-option')
  private selectOption (option : IOption) : IOption {
    return option
  }

  private focusElement (referenceValue : string | number) : void {
    const normalize = <T>(arr : T) : HTMLElement => (Array.isArray(arr) ? arr[0] : arr)
    normalize(this.$refs[referenceValue])?.focus()
  }

  private selectedStyle (item : IOption) : string {
    return item.value === this.selected.value
      ? 'text-darkgreen-600 bg-darkgreen-100'
      : 'hover:bg-gray-100 bg-white'
  }
}
