
import { Component, InjectReactive } from 'nuxt-property-decorator'
import { FSXABaseSection } from 'fsxa-pattern-library'
import { Image, Link, Section } from 'fsxa-api'
import { ITeaserData } from '../../../shared/general/interfaces/ITeaserData'
import { mapImage } from '../../../shared/general/services/ImageService'
import BaseGridLayout from '../../layouts/BaseGridLayout.vue'
import getLinkObject from '../../../shared/fsxa/services/LinkService'
import { ILink } from '../../../shared/general/interfaces/ILink'
import normalizeDataEntries from '../../../shared/fsxa/services/DataEntryService'
import { TDataEntry } from '../../../shared/fsxa/types/TDataEntry'

type TTeaser = {
  st_alt_text ?: string
  st_downloads ?: Section[]
  st_headline ?: string
  st_image ?: Image
  st_image_decorative ?: boolean
  st_link ?: Link
  st_text ?: string
}

interface IPayload {
  st_headline ?: string
  st_toggle_background ?: boolean
  st_teaser_type ?: {
    identifier ?: 'small' | 'large'
  }
  st_teasers ?: TDataEntry<TTeaser>[]
}

@Component({
  name: 'StTeaserDownloadLink',
  components: {
    BaseTeaser: () => import('../../base/BaseTeaser.vue'),
    BaseGridLayout,
    BaseHeadline: () => import('../../base/BaseHeadline.vue'),
  },
})
export default class StTeaserDownloadLink extends FSXABaseSection<IPayload> {
  @InjectReactive({ from: 'gridSize' }) gridSize! : 'small' | 'wide'

  private width = {
    small: `basis-full sm:basis-[calc(50%_-_12px)] md:basis-[calc(100%_/_3_-_16px)] ${
      this.gridSize && this.gridSize === 'small' ? '' : 'lg:basis-[calc(25%_-_18px)]'
    }`,
    large: 'basis-full sm:basis-[calc(50%_-_12px)] md:basis-[calc(100%_/_3_-_16px)] lg:basis-[calc(100%_/_3_-_16px)]',
  }

  private backgrounds = {
    white: 'bg-white',
    gray: 'bg-gray-100',
  }

  private get headline () : string {
    return this.payload.st_headline || ''
  }

  private get size () : string {
    return this.payload.st_teaser_type?.identifier || 'small'
  }

  private get teasers () : ITeaserData[] {
    return normalizeDataEntries(this.payload.st_teasers).map((teaser : TTeaser) : ITeaserData => {
      let link = {} as ILink

      if (teaser.st_link) {
        link = getLinkObject(teaser.st_link, this.getUrlByPageId)
      } else {
        link = getLinkObject(teaser.st_downloads?.[0], this.getUrlByPageId)
        link.showInfo = true
        link.displayInfoAs = 'row'

        if (!link.label) {
          link.globalLabelKey = 'download_label'
        }
      }

      link.inline = false

      return {
        image: teaser.st_image ? mapImage(teaser.st_image, teaser.st_alt_text, teaser.st_alt_text, teaser.st_image_decorative) : undefined,
        headline: teaser.st_headline || '',
        text: teaser.st_text,
        mediaType: 'image',
        link,
        forceRatio: true,
      }
    }) || []
  }

  private get anchorName () : string | undefined {
    return this.$store.getters['AnchorLinks/getAnchorNameForSection'](this.id)
  }

  private get background () : 'gray' | 'white' {
    return this.payload.st_toggle_background ? 'gray' : 'white'
  }
}
