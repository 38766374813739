
import { Component } from 'nuxt-property-decorator'
import { FSXABaseSection } from 'fsxa-pattern-library'
import { SmartSearch } from 'smartsearch.js'
import BaseGridLayout from '../../layouts/BaseGridLayout.vue'
import { globalLabelAsString, subscribeAction } from '../../../shared/general/services/StoreService'
import { ISearchResult } from '../../search/SearchResults.vue'
import {
  getSmartSearchInstance,
  search as smartSearch,
  TSmartSearchMapping,
  TSmartSearchParams,
  TSmartSearchResults,
} from '../../../shared/general/services/SmartSearchService'
import { getURLParameterValue, setURLParameterValue } from '../../../shared/general/services/URLParameterService'

interface IPayload {
  st_display_all ?: boolean
  st_display_events ?: boolean
  st_display_media ?: boolean
  st_display_press ?: boolean
  st_display_services ?: boolean
  st_show_datefilter_all ?: boolean
  st_show_datefilter_events ?: boolean
  st_show_datefilter_press ?: boolean
  st_show_industry_filter ?: boolean
  st_show_service_division_filter ?: boolean
  st_show_service_object_filter ?: boolean
  st_show_tags_all ?: boolean
  st_show_tags_events ?: boolean
  st_show_tags_press ?: boolean
  st_tab_label_all ?: string
  st_tab_label_events ?: string
  st_tab_label_media ?: string
  st_tab_label_press ?: string
  st_tab_label_services ?: string
  st_text ?: string
}

@Component({
  name: 'StSearchResult',
  components: {
    BaseGridLayout,
    SeparationLine: () => import('../../SeparationLine.vue'),
    BaseSearchInput: () => import('../../base/form/BaseSearchInput.vue'),
    SearchResults: () => import('../../search/SearchResults.vue'),
  },
})
export default class StSearchResult extends FSXABaseSection<IPayload> {
  private searchTerm : string = ''

  private pageNumber : number = 1

  private numberOfTotalResults : number = 0

  private showNoResultsLabel : boolean = false

  private showLoadingAnimation : boolean = false

  private searchResults : ISearchResult[] = []

  private smartSearchService! : () => Promise<SmartSearch>

  /**
   * Because of async component imports this flag prevents the following error in conjunction with the SmartSearch:
   * - TypeError: Cannot read properties of undefined (reading 'toLowerCase')
   */
  private mountedDone : boolean = false

  created () {
    const { SMART_SEARCH_HOST: host, PREPARED_SEARCH_BASE_SEARCH: preparedSearch } = this.$config
    this.smartSearchService = getSmartSearchInstance({ host, preparedSearch })

    this.searchTerm = this.urlSearchTerm
  }

  async mounted () {
    // Allows triggering the search from other components.
    subscribeAction({
      name: 'SmartSearch/triggerSearchResultSearch',
      callback: async (value : string) : Promise<void> => {
        this.searchTerm = value
        await this.search(value)
      },
    })

    window.addEventListener('popstate', this.onPopstate)

    await this.search(this.searchTerm)
    this.mountedDone = true
  }

  private async searchByUserAction (searchTermParam : string) : Promise<void> {
    if (!searchTermParam.length) return
    (document?.activeElement as HTMLElement)?.blur()
    setURLParameterValue('q', searchTermParam || undefined)
    await this.search(searchTermParam)
  }

  private async search (searchTermParam : string) : Promise<void> {
    const searchTerm : string = searchTermParam.trim()
    if (!searchTerm.length) return
    this.pageNumber = 1
    this.showLoadingAnimation = true
    this.searchTerm = searchTerm

    const results : TSmartSearchResults<ISearchResult> = await smartSearch(await this.smartSearchService(), this.searchMapping, this.searchParams)

    this.showLoadingAnimation = false
    this.numberOfTotalResults = results.totalNumberOfResults
    this.showNoResultsLabel = !results.searchResults.length
    this.searchResults = results.searchResults
  }

  private async loadMoreSearchResults () : Promise<void> {
    this.pageNumber += 1
    this.showLoadingAnimation = true

    const results : TSmartSearchResults<ISearchResult> = await smartSearch(await this.smartSearchService(), this.searchMapping, this.searchParams)

    this.showLoadingAnimation = false
    this.numberOfTotalResults = results.totalNumberOfResults
    this.searchResults.push(...results.searchResults)
  }

  beforeDestroy () {
    window.removeEventListener('popstate', this.onPopstate)
  }

  private get urlSearchTerm () : string {
    return getURLParameterValue('q')
  }

  /**
   * This will be called when:
   * - user changes browser history by clicking back or forward button
   */
  private onPopstate () : void {
    if (this.urlSearchTerm) {
      this.search(this.urlSearchTerm)
    } else {
      this.resetButtonClicked()
    }
  }

  /**
   * This value will be shown in the users input field.
   * Can be used to prevent certain values from being shown.
   */
  private get inputFieldSearchTerm () : string {
    return this.searchTerm
  }

  private get text () : string {
    return this.payload.st_text || ''
  }

  protected get resetSearchLabel () : string {
    return globalLabelAsString('reset_search_label')
  }

  protected get clearLabel () : string {
    return globalLabelAsString('clear_all_label')
  }

  private get searchMapping () : TSmartSearchMapping<ISearchResult> {
    return {
      link: { key: '' }, // key not important
      headline: { key: 'base_search_headline' },
      text: { key: 'base_search_text' },
      dateFrom: { key: 'base_search_date_from_date_range' },
      dateTo: { key: 'base_search_date_to_date_range' },
      eventLocation: { key: 'base_search_event_location' },
    }
  }

  private get searchParams () : TSmartSearchParams {
    return {
      searchTerm: this.searchTerm,
      locale: this.locale,
      pageNumber: this.pageNumber,
      pageSize: 10,
      customParams: [
        ['facet.filter.FS_project_uuid', this.$config.FSXA_PROJECT_ID], // always include!
        ['facet.filter.FS_project_uuid', this.$config.FSXA_REMOTES?.media?.id || ''],
        ['facet.filter.FS_show_in_this_language', 'true'],
        ['facet.filter.FS_subscribed_projects', this.$config.FSXA_PROJECT_ID],
      ],
    }
  }

  private resetButtonClicked () : void {
    this.pageNumber = 1
    this.searchTerm = ''
    this.numberOfTotalResults = 0
    this.showNoResultsLabel = false
    this.searchResults = []
    setURLParameterValue('q', undefined)
  }
}
