
import { FSXABaseSection } from 'fsxa-pattern-library'
import { RichTextElement } from 'fsxa-api'
import { Component } from 'nuxt-property-decorator'
import BaseGridLayout from '../../layouts/BaseGridLayout.vue'
import { IBaseTable } from '../../base/BaseTable.vue'
import getLinkObject from '../../../shared/fsxa/services/LinkService'

interface IPayload {
  st_headline ?: string
  st_table ?: RichTextElement[]
  st_matrix ?: boolean
}

@Component({
  name: 'StTable',
  components: {
    BaseTable: () => import('../../base/BaseTable.vue'),
    BaseGridLayout,
  },
})
export default class StTable extends FSXABaseSection<IPayload> {
  private table : IBaseTable = {}

  created () {
    const { st_headline: headline, st_matrix: matrixDesign, st_table: content } = this.payload || {}
    const newContent = this.mapLinks(JSON.parse(JSON.stringify(content)))
    this.table = { headline, matrixDesign, content: newContent }
  }

  private get anchorName () : string | undefined {
    return this.$store.getters['AnchorLinks/getAnchorNameForSection'](this.id)
  }

  private mapLinks (content : any) : any {
    if (!content) return content

    if (Array.isArray(content)) {
      return content.map((element) => this.mapLinks(element))
    }

    if (content.type === 'link') {
      content.data.data = getLinkObject(content.data, this.getUrlByPageId)
    }

    if (typeof content.content !== 'string') {
      content.content = this.mapLinks(content.content)
    }

    return content
  }
}
