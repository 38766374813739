
import { FSXABaseSection, FSXARichText } from 'fsxa-pattern-library'
import { Component } from 'nuxt-property-decorator'
import { ComparisonQueryOperatorEnum } from 'fsxa-api'
import BaseGridLayout from '../../layouts/BaseGridLayout.vue'
import ICheckbox from '../../../shared/general/interfaces/ICheckbox'
import IThirdPartyToolDetailsData, {
  TPrivacySettingsCategories,
} from '../../../shared/fsxa/interfaces/IThirdPartyToolDetailsData'
import getLinkObject from '../../../shared/fsxa/services/LinkService'
import { ILink } from '../../../shared/general/interfaces/ILink'
import { globalLabel, globalLabelAsString } from '../../../shared/general/services/StoreService'
import {
  IStatus, setAll, setOne, statusAll,
} from '../../../shared/general/services/privacy/PrivacySettingsService'
import fsxaProxyApiRemote from '../../../shared/fsxa/services/FsxaProxyApiRemote'

interface IPayload {
  st_text : FSXARichText
}

interface ICookieData {
  label : string
  value : string | ILink
}

type TPrivacySettingCheckbox = {
  [key : string] : { value : boolean, label : string, category : TPrivacySettingsCategories, disabled ?: boolean }
}

type TCategoryCheckbox = {
  [key : string] : { value : boolean, label ?: string, disabled ?: boolean, indeterminate ?: boolean }
}

type PrivacySettingsRequirements = {
  [key in TPrivacySettingsCategories] : IThirdPartyToolDetailsData[]
}

@Component({
  name: 'StPrivacySettings',
  components: {
    SeparationLine: () => import('../../SeparationLine.vue'),
    BaseLink: () => import('../../base/BaseLink.vue'),
    'rich-text': FSXARichText,
    BaseButton: () => import('../../base/BaseButton.vue'),
    BaseGridLayout,
    BaseCheckbox: () => import('../../base/form/BaseCheckbox.vue'),
    BaseAccordion: () => import('../../base/accordion/BaseAccordion.vue'),
    BaseAccordionElement: () => import('../../base/accordion/BaseAccordionElement.vue'),
  },
})
export default class StPrivacySettings extends FSXABaseSection<IPayload> {
  private checkboxes : TPrivacySettingCheckbox = {}

  private allPrivacySettings : IThirdPartyToolDetailsData[] = []

  private privacySettings : PrivacySettingsRequirements = {
    necessary: [],
    comfort: [],
    statistics: [],
    marketing: [],
  }

  private categoryAccordionStates : Record<string, boolean> = {
    necessary: false,
    comfort: false,
    statistics: false,
    marketing: false,
  }

  private categoryCheckboxes : TCategoryCheckbox = {
    necessary: {
      value: true,
      disabled: true,
      indeterminate: false,
    },
    comfort: {
      value: false,
      indeterminate: false,
    },
    statistics: {
      value: false,
      indeterminate: false,
    },
    marketing: {
      value: false,
      indeterminate: false,
    },
  }

  private async created () : Promise<void> {
    await this.loadPrivacySettings()
    this.updateCheckboxes()
    this.categories.filter((category) => category !== 'necessary')
      .forEach((category) => this.revalidateCategoryCheckbox(category))
  }

  private get categories () : TPrivacySettingsCategories[] {
    return Object.keys(this.categoryCheckboxes).map((key) => key as TPrivacySettingsCategories)
  }

  private get text () : FSXARichText {
    return this.payload.st_text
  }

  private get savePrivacySettingsLabel () : string {
    return globalLabelAsString('save_privacy_settings_label')
  }

  private get saveAllPrivacySettingsLabel () : string {
    return globalLabelAsString('cookie_banner_accept_all_label')
  }

  private get showMoreInformationLabel () : string {
    return globalLabelAsString('show_more_information')
  }

  private get showLessInformationLabel () : string {
    return globalLabelAsString('show_less_information')
  }

  private async loadPrivacySettings () : Promise<void> {
    this.allPrivacySettings = await this.fetchPrivacySettings()

    this.allPrivacySettings
      .filter((setting) => !!setting.data.tt_category?.key)
      .filter((setting) => Object.keys(this.privacySettings).includes(setting.data.tt_category.key))
      .forEach((setting) => this.storeIntoPrivacySettings(setting))
  }

  private async fetchPrivacySettings () : Promise<IThirdPartyToolDetailsData[]> {
    const { items } = await fsxaProxyApiRemote.fetchByFilter({
      filters: [
        {
          field: 'entityType',
          operator: ComparisonQueryOperatorEnum.EQUALS,
          value: 'third_party_tool_details',
        },
        {
          field: 'schema',
          operator: ComparisonQueryOperatorEnum.EQUALS,
          value: 'global_data',
        },
      ],
      locale: this.locale,
    })

    return items as IThirdPartyToolDetailsData[]
  }

  private acceptAllPrivacySettings () : void {
    this.categoryCheckboxes.comfort.indeterminate = false
    this.categoryCheckboxes.marketing.indeterminate = false
    this.categoryCheckboxes.statistics.indeterminate = false
    this.categoryCheckboxes.comfort.value = true
    this.categoryCheckboxes.marketing.value = true
    this.categoryCheckboxes.statistics.value = true

    const keys = Object.keys(this.checkboxes)
    keys.forEach((key) => {
      this.checkboxes[key].value = true
    })

    setAll(keys, true)

    this.$store.commit('Snackbar/set', globalLabel('save_privacy_settings_success_alert'))
  }

  private savePrivacySettings () : void {
    Object.entries(this.checkboxes).forEach(([key, checkbox]) => {
      setOne(key, checkbox.value)
    })

    this.$store.commit('Snackbar/set', globalLabel('save_privacy_settings_success_alert'))
  }

  private handleCategoryCheckboxClick (key : TPrivacySettingsCategories, event : ICheckbox) : void {
    const category = key
    if (category !== 'necessary') {
      this.categoryCheckboxes[category].value = event.value
      this.categoryCheckboxes[category].indeterminate = event.indeterminate

      this.getPrivacySettingsKeysOfCategory(category).forEach((pKey) => {
        if (this.checkboxes[pKey]) {
          this.checkboxes[pKey].value = event.value
        }
      })
    }
  }

  private handlePrivacySettingClick (key : string, event : ICheckbox) : void {
    this.checkboxes[key].value = event.value
    this.revalidateCategoryCheckbox(this.checkboxes[key].category)
  }

  private categoryLabel (key : string) : string {
    const categoryLabels : Record<string, string> = {
      necessary: globalLabelAsString('necessary_cookies_name_label'),
      comfort: globalLabelAsString('comfort_cookies_name_label'),
      statistics: globalLabelAsString('statistic_cookies_name_label'),
      marketing: globalLabelAsString('marketing_cookies_name_label'),
    }
    return categoryLabels[key]
  }

  private categoryCheckboxText (key : string) : string {
    const text : Record<string, string> = {
      necessary: globalLabelAsString('necessary_cookies_text_label'),
      comfort: globalLabelAsString('comfort_cookies_text_label'),
      statistics: globalLabelAsString('statistic_cookies_text_label'),
      marketing: globalLabelAsString('marketing_cookies_text_label'),
    }
    return text[key]
  }

  private onAccordionStateChange (category : TPrivacySettingsCategories, isActive : boolean) : void {
    this.categoryAccordionStates[category] = isActive
  }

  private categoryAccordionTitle (category : TPrivacySettingsCategories) : string {
    return this.categoryAccordionStates[category] ? this.showLessInformationLabel : this.showMoreInformationLabel
  }

  private storeIntoPrivacySettings (setting : IThirdPartyToolDetailsData) : void {
    const category = setting.data.tt_category.key
    const label = setting.data.tt_name
    this.privacySettings[category].push(setting)

    // initialize with "false" if category is "not" 'necessary'
    const checkbox = {
      value: category === 'necessary', label, category, disabled: category === 'necessary',
    }
    this.checkboxes = { ...this.checkboxes, [setting.data.tt_key]: checkbox }
  }

  private updateCheckboxes () : void {
    const statuses = statusAll()
    statuses.forEach((status) => this.updateCheckbox(status))
  }

  private updateCheckbox (status : IStatus) : void {
    const checkbox = this.checkboxes[status.key]
    if (!checkbox) return
    checkbox.value = status.value
  }

  private revalidateCategoryCheckbox (category : string) : void {
    let atLeastOneChecked = false
    let allChecked = true

    Object.values(this.checkboxes)
      .filter((checkbox) => checkbox.category === category)
      .forEach((checkbox) => {
        if (checkbox.value) {
          atLeastOneChecked = true
        } else {
          allChecked = false
        }
      })

    if (allChecked) {
      this.categoryCheckboxes[category].indeterminate = false
      this.categoryCheckboxes[category].value = true
    } else {
      this.categoryCheckboxes[category].indeterminate = atLeastOneChecked
      this.categoryCheckboxes[category].value = false
    }
  }

  private getPrivacySettingsKeysOfCategory (category : TPrivacySettingsCategories) : string[] {
    return Object.entries(this.checkboxes)
      .filter(([, checkbox]) => checkbox.category === category)
      .map(([key]) => key)
  }

  private mapSettingToCookieData (setting : IThirdPartyToolDetailsData) : ICookieData[] {
    return [
      {
        label: `${globalLabelAsString('thirdPartyTool_provider')}:`,
        value: setting.data.tt_provider,
      },
      {
        label: `${globalLabelAsString('thirdPartyTool_description')}:`,
        value: setting.data.tt_description,
      },
      {
        label: `${globalLabelAsString('thirdPartyTool_privacyStatement')}:`,
        value: getLinkObject(setting.data.tt_privacy_statement, this.getUrlByPageId) || {},
      },
      {
        label: `${globalLabelAsString('thirdPartyTool_host')}:`,
        value: setting.data.tt_host,
      },
      {
        label: `${globalLabelAsString('thirdPartyTool_cookieName')}:`,
        value: setting.data.tt_cookie_name,
      },
      {
        label: `${globalLabelAsString('thirdPartyTool_cookieDuration')}:`,
        value: setting.data.tt_cookie_duration,
      },
    ]
  }
}
