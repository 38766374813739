
import { FSXABaseSection } from 'fsxa-pattern-library'
import { Option } from 'fsxa-api'
import { Component } from 'nuxt-property-decorator'
import BaseGridLayout from '../../layouts/BaseGridLayout.vue'
import ILocationData from '../../../shared/fsxa/interfaces/ILocationData'
import createContactArray from '../../../shared/fsxa/services/ContactView'
import IContactElement from '../../../shared/general/interfaces/IContactElement'
import { TRemoteDatasetIndex } from '../../../shared/fsxa/types/TRemoteDataset'
import getOrFetchRemoteDatasets, { getRemoteDatasetsFromStore } from '../../../shared/fsxa/services/RemoteDatasetService'
import { TLocationView } from '../../../shared/fsxa/types/TView'

interface IPayload {
  st_headline : string
  st_location_remote : TRemoteDatasetIndex
  st_location_view_remote : TRemoteDatasetIndex
  st_zoom : Option
}

@Component({
  name: 'StDirectionMap',
  components: {
    BaseGridLayout,
    DirectionMap: () => import('../../DirectionMap/DirectionMap.vue'),
  },
})
export default class StDirectionMap extends FSXABaseSection<IPayload> {
  async mounted () {
    await this.fetchRemoteDatasets()
  }

  async serverPrefetch () {
    await this.fetchRemoteDatasets()
  }

  async fetchRemoteDatasets () : Promise<void> {
    await Promise.all([
      getOrFetchRemoteDatasets(this.locationRemoteDatasetIndex),
      getOrFetchRemoteDatasets(this.locationViewRemoteDatasetIndex),
    ])
  }

  private get locationRemoteDatasetIndex () : TRemoteDatasetIndex | undefined {
    return this.payload.st_location_remote
  }

  private get locationViewRemoteDatasetIndex () : TRemoteDatasetIndex | undefined {
    return this.payload.st_location_view_remote
  }

  private get locationRemote () : ILocationData[] {
    return getRemoteDatasetsFromStore(this.locationRemoteDatasetIndex)
  }

  private get locationViewRemote () : TLocationView[] {
    return getRemoteDatasetsFromStore(this.locationViewRemoteDatasetIndex)
  }

  private get zoomLevel () : number {
    return +this.payload.st_zoom.value || 11
  }

  private get anchorName () : string | undefined {
    return this.$store.getters['AnchorLinks/getAnchorNameForSection'](this.id)
  }

  private get headline () : string {
    return this.payload.st_headline || ''
  }

  private get googleMapsApiKey () : string {
    return this.globalSettings?.data.ps_google_maps_api_key
  }

  private get geoCoordinates () : { lat : number, lng : number } {
    return {
      lat: this.locationRemote[0]?.data?.tt_latitude || 0,
      lng: this.locationRemote[0]?.data?.tt_longitude || 0,
    }
  }

  private get locationInfo () : IContactElement[] {
    const locationData = this.locationRemote[0]
    if (!locationData?.data) return []

    return createContactArray(
      locationData,
      this.locationViewRemote[0]?.data?.tt_config || [],
      this.getUrlByPageId,
    )
  }
}
