
import { FSXABaseSection } from 'fsxa-pattern-library'
import { Component } from 'nuxt-property-decorator'
import { ITeaserData } from '../../../shared/general/interfaces/ITeaserData'
import { globalLabelAsString } from '../../../shared/general/services/StoreService'
import { ILink } from '../../../shared/general/interfaces/ILink'
import {
  normalizeTeaserText, searchTeasers, TTeaserType,
} from '../../../shared/general/services/SmartSearchTeaserService'
import ISmartSearchHost from '../../../shared/general/interfaces/ISmartSearchHost'
import getOrFetchRemoteDatasets, { getRemoteDatasetsFromStore } from '../../../shared/fsxa/services/RemoteDatasetService'
import { TRemoteDatasetIndex } from '../../../shared/fsxa/types/TRemoteDataset'
import ITagData from '../../../shared/fsxa/interfaces/ITagData'

export interface IPayload {
  st_headline ?: string
  st_tags_remote ?: TRemoteDatasetIndex
}

@Component({
  name: 'StTeaserPressReleasesHome',
  components: {
    TeaserPressReleaseOverview: () => import('../../teaser/pressRelease/TeaserPressReleaseOverview.vue'),
  },
})
export default class StTeaserPressReleasesHome extends FSXABaseSection<IPayload> {
  teasers : ITeaserData[] = []

  async mounted () {
    await this.fetchRemoteDatasets()
    this.teasers = await this.getTeasers()
  }

  async serverPrefetch () {
    await this.fetchRemoteDatasets()
  }

  async fetchRemoteDatasets () : Promise<void> {
    await getOrFetchRemoteDatasets(this.tagsDatasetIndex)
  }

  private get anchorName () : string | undefined {
    return this.$store.getters['AnchorLinks/getAnchorNameForSection'](this.id)
  }

  private get headline () : string | undefined {
    return this.payload.st_headline || ''
  }

  private get allPressReleasesLink () : ILink {
    return {
      url: this.getUrlByPageId(
        this.globalSettings?.data.ps_country_project_settings?.pt_press_overview_page?.referenceId || '',
      ) ?? '#',
      label: globalLabelAsString('press_link_title_label'),
      iconName: 'chevron-right',
    }
  }

  private get noPressReleasesText () : string {
    return globalLabelAsString('no_press_releases_available_label')
  }

  private get teaserTags () : string[] {
    return this.tagsRemote.map((tag) => tag.data?.tt_name || '').filter((tagName) => tagName.length)
  }

  private get tagsDatasetIndex () : TRemoteDatasetIndex | undefined {
    return this.payload.st_tags_remote
  }

  private get tagsRemote () : ITagData[] {
    return getRemoteDatasetsFromStore(this.tagsDatasetIndex)
  }

  private async getTeasers () : Promise<ITeaserData[]> {
    const smartSearchHost : ISmartSearchHost = {
      host: this.$config.SMART_SEARCH_HOST,
      preparedSearch: this.$config.PREPARED_SEARCH_TEASER_OVERVIEW,
      projectId: this.$config.FSXA_PROJECT_ID,
      remoteProjectId: this.$config.FSXA_REMOTES?.media?.id || '',
    }
    const teaserType : TTeaserType = 'press_release'
    const pageNumber : number = 1
    const pageSize : number = 3
    const result = await searchTeasers(smartSearchHost, {
      locale: this.locale, teaserType, teaserTags: this.teaserTags, pageNumber, pageSize, sort: ['sortDateFrom', 'desc'],
    })
    return result.searchResults.map((searchResult) => normalizeTeaserText(searchResult, (searchRes) => ({ from: searchRes.publishFrom })))
  }
}
