
import { Component } from 'nuxt-property-decorator'
import BaseTeaserRc from '../base/BaseTeaserRc'
import IImage from '../../../shared/general/interfaces/IImage'
import BaseGridLayout from '../../layouts/BaseGridLayout.vue'
import mapToResolutions from '../../../shared/general/services/ImageService'
import IOption from '../../../shared/general/interfaces/IOption'
import { ITeaserData } from '../../../shared/general/interfaces/ITeaserData'
import { formatJoinFromToDate } from '../../../shared/general/services/DateTimeService'
import getOrFetchRemoteDatasets, { getRemoteDatasetsFromStore } from '../../../shared/fsxa/services/RemoteDatasetService'
import { TRemoteDatasetIndex } from '../../../shared/fsxa/types/TRemoteDataset'
import IEventData from '../../../shared/fsxa/interfaces/IEventData'

interface IPayload {
  st_box_position ?: boolean
  st_column ?: IOption
  st_image_text ?: Boolean
  st_event_global_remote ?: TRemoteDatasetIndex
  st_event_local ?: IEventData[]
}

@Component({
  name: 'StTeaserRcEvent',
  components: {
    BaseGridLayout,
    BaseTeaser: () => import('../../base/BaseTeaser.vue'),
  },
})
export default class StTeaserRcEvent extends BaseTeaserRc<IPayload> {
  async mounted () {
    await this.fetchRemoteDatasets()
    super.mounted()
  }

  async serverPrefetch () {
    await this.fetchRemoteDatasets()
  }

  async fetchRemoteDatasets () : Promise<void> {
    await getOrFetchRemoteDatasets(this.eventGlobalDatasetIndex)
  }

  private get widthClass () : string {
    return this.numberOfColumns === 2
      ? 'w-full sm:w-[calc(100%/2-1.125rem)] md:w-[calc(100%/3-1.125rem)] '
        + 'lg:w-[calc(((100%/4)*2)-1.125rem)] lg:aspect-video'
      : 'w-full sm:w-[calc(100%/2-1.125rem)] md:w-[calc(100%/3-1.125rem)] lg:w-[calc(100%/4-1.125rem)]'
  }

  private get eventsFromPayload () : IEventData {
    return [...(this.eventsGlobalRemote || []), ...(this.payload.st_event_local || [])][0]
  }

  private get teaserData () : ITeaserData {
    const image : IImage = {
      altText: this.eventsFromPayload?.data?.tt_teaser_image_alt_text || '',
      title: this.eventsFromPayload?.data?.tt_teaser_image_alt_text || '',
      resolutions: mapToResolutions(this.eventsFromPayload?.data?.tt_teaser_image?.resolutions),
    }

    return {
      columns: this.payload.st_column?.label || '1',
      headline: this.eventsFromPayload?.data?.tt_headline || '',
      text: this.eventsFromPayload?.data?.tt_overline ?? '',
      image,
      link: {
        url: this.eventsFromPayload?.route || this.eventsFromPayload?.routes?.[0]?.route,
        globalLabelKey: 'specific_event_link_label',
        iconName: 'chevron-right',
      },
      mediaType: this.payload.st_image_text ? 'image' : 'text',
      date: formatJoinFromToDate(
        { from: this.eventsFromPayload?.data?.tt_date_from, to: this.eventsFromPayload?.data?.tt_date_to },
      ),
      location: this.eventsFromPayload?.data?.tt_location,
    }
  }

  private get eventGlobalDatasetIndex () : TRemoteDatasetIndex | undefined {
    return this.payload.st_event_global_remote
  }

  private get eventsGlobalRemote () : IEventData[] {
    return getRemoteDatasetsFromStore(this.eventGlobalDatasetIndex)
  }
}
