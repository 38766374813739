
import { Component } from 'nuxt-property-decorator'
import { FSXABaseSection } from 'fsxa-pattern-library'
import { Image, Section } from 'fsxa-api'
import IImage from '../../../shared/general/interfaces/IImage'
import mapToResolutions from '../../../shared/general/services/ImageService'
import BaseGridLayout from '../../layouts/BaseGridLayout.vue'
import getLinkObject from '../../../shared/fsxa/services/LinkService'
import { ITeaserData } from '../../../shared/general/interfaces/ITeaserData'
import getOrFetchRemoteDatasets, { getRemoteDatasetsFromStore } from '../../../shared/fsxa/services/RemoteDatasetService'
import { TRemoteDatasetIndex } from '../../../shared/fsxa/types/TRemoteDataset'

interface ITeaserPayload {
  data ?: {
    tt_media_asset ?: Section[]
    tt_teaser_headline ?: string
    tt_teaser_image ?: Image
    tt_teaser_image_alt_text ?: string
    tt_teaser_text ?: string
  }
}

interface IPayload {
  st_global_media_download_remote ?: TRemoteDatasetIndex
  st_local_media_download ?: ITeaserPayload[]
}

@Component({
  name: 'StTeaserMediaDownload',
  components: {
    BaseTeaser: () => import('../../base/BaseTeaser.vue'),
    BaseGridLayout,
  },
})
export default class StTeaserMediaDownload extends FSXABaseSection<IPayload> {
  async mounted () {
    await this.fetchRemoteDatasets()
  }

  async serverPrefetch () {
    await this.fetchRemoteDatasets()
  }

  async fetchRemoteDatasets () : Promise<void> {
    await getOrFetchRemoteDatasets(this.globalMediaDownloadDatasetIndex)
  }

  private get teasers () : ITeaserData[] {
    const downloads = this.payload?.st_local_media_download?.length
      ? this.payload?.st_local_media_download
      : this.globalMediaDownloadsRemote

    return downloads?.map((teaser : ITeaserPayload) : ITeaserData => {
      const link = getLinkObject(teaser.data?.tt_media_asset?.[0], this.getUrlByPageId)
      link.showInfo = true
      link.displayInfoAs = 'row'
      link.label = link.label ? link.label : teaser.data?.tt_teaser_headline || ''

      return {
        image: this.mapImage(teaser),
        headline: teaser.data?.tt_teaser_headline || '',
        text: teaser.data?.tt_teaser_text || '',
        mediaType: 'image',
        teaserVariant: 'horizontal-image-start',
        link,
      }
    }) || []
  }

  private get anchorName () : string | undefined {
    return this.$store.getters['AnchorLinks/getAnchorNameForSection'](this.id)
  }

  private get globalMediaDownloadDatasetIndex () : TRemoteDatasetIndex | undefined {
    return this.payload.st_global_media_download_remote
  }

  private get globalMediaDownloadsRemote () : ITeaserPayload[] {
    return getRemoteDatasetsFromStore(this.globalMediaDownloadDatasetIndex)
  }

  private mapImage (teaser : ITeaserPayload): (IImage | undefined) {
    if (!teaser.data?.tt_teaser_image) return undefined
    return {
      altText: teaser.data?.tt_teaser_image_alt_text || '',
      title: teaser.data?.tt_teaser_image_alt_text || '',
      resolutions: mapToResolutions(teaser.data?.tt_teaser_image?.resolutions),
    }
  }
}
