
import { Component } from 'nuxt-property-decorator'
import { FSXABaseSection } from 'fsxa-pattern-library'
import BaseGridLayout from '../../layouts/BaseGridLayout.vue'
import { ITeaserData } from '../../../shared/general/interfaces/ITeaserData'
import { fetchNormalized } from '../../../shared/fsxa/services/MediaDownloadService'

type TTeaserState = {
  page : number
  total : number
  buffer : ITeaserData[]
  done : boolean
}

@Component({
  name: 'StTeaserOverviewMediaDownloads',
  components: {
    BaseGridLayout,
    TeaserOverviewMediaDownloads: () => import('../../teaser/overview/TeaserOverviewMediaDownloads.vue'),
  },
})
export default class StTeaserOverviewMediaDownloads extends FSXABaseSection<{}> {
  private teasers : ITeaserData[] = []

  private localTeaserState : TTeaserState = {
    page: 0,
    total: 0,
    buffer: [],
    done: false,
  }

  private globalTeaserState : TTeaserState = {
    page: 0,
    total: 0,
    buffer: [],
    done: false,
  }

  private pageSize : number = 12

  private loading : boolean = true

  private signal : number = 0

  created () {
    this.$store.commit('Page/setPageType', 'TeaserOverview')
  }

  async mounted () {
    await this.loadMoreTeasers()
  }

  private get anchorName () : string | undefined {
    return this.$store.getters['AnchorLinks/getAnchorNameForSection'](this.id)
  }

  private async loadMoreTeasers () : Promise<void> {
    this.loading = true
    this.signal += 1

    const currentSignal = this.signal
    const result = await this.loadTeaserBatch(this.localTeaserState, this.globalTeaserState)

    if (currentSignal === this.signal) {
      this.teasers.push(...result.teasers)
      this.localTeaserState = result.localTeaserState
      this.globalTeaserState = result.globalTeaserState
      this.loading = false
    }
  }

  private async loadTeaserBatch (
    localTeaserState : TTeaserState,
    globalTeaserState : TTeaserState,
  ) : Promise<{ teasers : ITeaserData[], localTeaserState : TTeaserState, globalTeaserState : TTeaserState }> {
    const teasers : ITeaserData[] = []
    const currentLocalState = { ...localTeaserState }
    const currentGlobalState = { ...globalTeaserState }

    while (teasers.length < this.pageSize && !(this.isBufferDoneAndEmpty(currentLocalState) && this.isBufferDoneAndEmpty(currentGlobalState))) {
      const firstLocalTeaserPromise = this.peekFirstTeaser('local', currentLocalState)
      const firstGlobalTeaserPromise = this.peekFirstTeaser('global', currentGlobalState)

      // eslint-disable-next-line no-await-in-loop
      const firstLocalTeaserItem = await firstLocalTeaserPromise
      // eslint-disable-next-line no-await-in-loop
      const firstGlobalTeaserItem = await firstGlobalTeaserPromise

      if (firstLocalTeaserItem && this.compareTeasers(firstLocalTeaserItem, firstGlobalTeaserItem)) {
        currentLocalState.buffer.shift()
        teasers.push(firstLocalTeaserItem)
      } else if (firstGlobalTeaserItem) {
        currentGlobalState.buffer.shift()
        teasers.push(firstGlobalTeaserItem)
      }
    }

    return {
      teasers,
      localTeaserState: currentLocalState,
      globalTeaserState: currentGlobalState,
    }
  }

  private async peekFirstTeaser (type : 'local' | 'global', currentState : TTeaserState) : Promise<ITeaserData | undefined> {
    if (currentState.buffer.length > 0) return currentState.buffer[0]
    if (currentState.done) return undefined

    currentState.page += 1

    return fetchNormalized(
      this.fsxaApi,
      this.locale,
      type,
      currentState.page,
      this.pageSize,
      this.getUrlByPageId,
    ).then((data) => {
      currentState.total = data.total
      currentState.buffer = data.teasers
      currentState.done = data.total <= currentState.page * this.pageSize
      return data.teasers[0]
    })
  }

  private isBufferDoneAndEmpty (teaserState : TTeaserState) : boolean {
    return teaserState.done && teaserState.buffer.length === 0
  }

  private compareTeasers (localTeaserItem : ITeaserData, globalTeaserItem : ITeaserData | undefined) : boolean {
    if (!globalTeaserItem) return true
    return localTeaserItem.headline < globalTeaserItem.headline // Use the same comparison logic as CaaS instead of localCompare()
  }
}
