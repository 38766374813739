
import {
  Component, Prop, Vue,
} from 'nuxt-property-decorator'
import BaseImageVideo from '../ImageVideo/BaseImageVideo.vue'
import BaseLink from './BaseLink.vue'
import IImage from '../../shared/general/interfaces/IImage'
import IVideo from '../../shared/general/interfaces/IVideo'
import { ILink } from '../../shared/general/interfaces/ILink'
import { TMediaType } from '../../shared/general/types/TMediaType'
import { TTeaserVariant } from '../../shared/general/types/TTeaserVariant'
import IImageVideoContent from '../../shared/general/interfaces/IImageVideoContent'
import { openLightbox } from '../../shared/general/services/LightboxService'

@Component({
  name: 'BaseTeaser',
  components: {
    BaseImageVideo,
    BaseLink,
    BaseIcon: () => import('./BaseIcon.vue'),
  },
})
export default class BaseTeaser extends Vue {
  @Prop({ default: '1' }) columns! : '1' | '2'

  @Prop() headline ?: string

  @Prop() text ?: string

  @Prop() tags ?: string

  @Prop() image ?: IImage

  @Prop({ required: true }) link! : ILink

  @Prop() mediaType ?: TMediaType

  @Prop() video ?: IVideo

  @Prop() date ?: string

  @Prop() location ?: string

  @Prop({ default: 'bg-white' }) bgColor! : string

  @Prop({ default: 'vertical' }) teaserVariant! : TTeaserVariant

  @Prop({ default: '16:9' }) aspectRatio! : string

  @Prop({ default: false }) large! : boolean

  @Prop({ default: false }) fullSizeImage! : boolean

  @Prop({ default: false }) showDownloadIcon! : boolean

  @Prop({ default: false }) forceRatio! : boolean

  @Prop({ default: false }) showDateAndLocation! : boolean

  private wrapperClasses = {
    'rc-top': 'flex h-full w-full flex-col sm:flex-row',
    'rc-bottom': 'flex h-full w-full flex-col sm:flex-row',
    'horizontal-image-start': 'grid grid-cols-1 sm:grid-cols-8 grid-flow-row-dense col-span-4 sm:col-span-8 md:col-span-12 w-full',
    'horizontal-image-end': 'grid grid-cols-1 sm:grid-cols-8 grid-flow-row-dense col-span-4 sm:col-span-8 md:col-span-12 w-full',
    'horizontal-image-start-large': 'flex flex-col md:flex-row',
    'horizontal-image-end-large': 'flex flex-col md:flex-row-reverse',
    vertical: 'flex flex-col gap-0 max-w-full w-full h-full',
  }

  private aspectRatioClasses = {
    '3:4': 'aspect-3/4',
    '4:3': 'aspect-4/3',
    '16:9': 'aspect-video',
    '9:16': 'aspect-9/16',
  }

  private imageWrapperClasses = {
    'rc-top': '',
    'rc-bottom': '',
    'horizontal-image-start': 'sm:col-span-4 md:col-span-3',
    'horizontal-image-end': 'sm:col-span-4 md:col-span-3',
    'horizontal-image-start-large': 'md:max-w-[50%]',
    'horizontal-image-end-large': 'md:max-w-[50%]',
    vertical: '',
  }

  private get headlineClasses () : string {
    return this.large ? 'text-lg sm:text-3xl' : 'text-xl'
  }

  private get boxClasses () : string {
    if (!this.showMedia && !this.showDateAndLocation) return 'h-full w-full p-5'

    const classes = {
      'rc-top': 'sm:absolute left-0 right-0 py-3 px-4 m-2 top-0',
      'rc-bottom': 'sm:absolute left-0 right-0 py-3 px-4 m-2 bottom-0',
      'horizontal-image-start': `grow w-full ${
        this.showDateAndLocation ? 'px-5 pb-5 pt-4 sm:p-5' : 'p-5'} ${
        this.downloadIconVisible ? 'sm:col-span-7' : 'md:col-span-5 sm:col-span-4'
      }`,
      'horizontal-image-end': `grow w-full p-5 row-1 ${
        this.downloadIconVisible ? 'sm:col-span-7' : 'md:col-span-5 sm:col-span-4'
      }`,
      'horizontal-image-start-large': 'grow w-full p-5',
      'horizontal-image-end-large': 'grow w-full p-5',
      vertical: 'grow w-full p-5',
    }

    return classes[this.teaserVariant]
  }

  private get showMedia () : boolean {
    return this.mediaType === 'video' || (this.mediaType === 'image' && !!this.image?.resolutions.length)
  }

  private get imageHeightClass () : string {
    if (this.fullSizeImage) return '!h-full'
    if (this.teaserVariant.includes('horizontal-image-')) return 'h-full'
    return this.columns === '1' ? 'h-auto' : 'sm:h-full'
  }

  private get downloadIconVisible () : boolean {
    return ['horizontal-image-start', 'horizontal-image-end']
      .includes(this.teaserVariant) && this.link.iconName === 'arrow-down-to-line' && this.showDownloadIcon
  }

  private showLightbox (content : IImageVideoContent) : void {
    if (!['BaseVideo', 'BaseHtmlPlayer'].includes(content.tag)) return
    openLightbox(content)
  }
}
