
import {
  Component, InjectReactive, Prop, Vue, Watch,
} from 'nuxt-property-decorator'
import { FSXARichText } from 'fsxa-pattern-library'
import {
  ComparisonQueryOperatorEnum,
} from 'fsxa-api'
import BaseGridLayout from './layouts/BaseGridLayout.vue'
import BaseButton from './base/BaseButton.vue'
import IThirdPartyToolDetailsData from '../shared/fsxa/interfaces/IThirdPartyToolDetailsData'
import { ILink } from '../shared/general/interfaces/ILink'
import BaseLink from './base/BaseLink.vue'
import BaseIcon from './base/BaseIcon.vue'
import { globalLabelAsString } from '../shared/general/services/StoreService'
import { setAll } from '../shared/general/services/privacy/PrivacySettingsService'
import fsxaProxyApiRemote from '../shared/fsxa/services/FsxaProxyApiRemote'

@Component({
  name: 'TheCookieBanner',
  components: {
    'rich-text': FSXARichText,
    BaseGridLayout,
    BaseButton,
    BaseLink,
    BaseIcon,
  },
})
export default class TheCookieBanner extends Vue {
  @InjectReactive({ from: 'getUrlByPageId' }) getUrlByPageId! : Function

  @Prop() cookieBannerHeadline ?: string

  @Prop() cookieBannerText ?: FSXARichText[]

  @Prop() cookieBannerLink ?: ILink

  @Prop({ default: true }) showRejectAllButton! : boolean

  @Prop({ default: true }) showAcceptAllButton! : boolean

  @Prop({ default: false }) showOkayButton! : boolean

  private cookieBannerVisible = true

  mounted () {
    if (!this.showCookieBanner) return

    document.body.classList.add('show-cb')

    window.addEventListener('resize', () => {
      document.documentElement.style.setProperty('--the-cookie-banner-height', 'auto')
      this.setHeight(this.$el.clientHeight)
    })

    this.$nextTick(() => {
      this.setHeight(this.$el.clientHeight)
    })

    setTimeout(() => {
      document.body.classList.add('cb-visible')
    }, 1500)
  }

  private get locale () : string {
    return this.$store.state.Locale.fsxaLocale || 'en_US'
  }

  private get rejectAllLabel () : string {
    return globalLabelAsString('cookie_banner_reject_all_label')
  }

  private get acceptAllLabel () : string {
    return globalLabelAsString('cookie_banner_accept_all_label')
  }

  private get okayLabel () : string {
    return globalLabelAsString('okay_label')
  }

  private async rejectAll () : Promise<void> {
    const data = await this.fetchThirdPartyToolDetailsData()

    const nonTechnicalPrivacyKeys = data.filter((item) => item.data.tt_category?.key !== 'necessary').map((item) => item.data.tt_key)
    setAll(nonTechnicalPrivacyKeys, false)

    const technicalPrivacyKeys = data.filter((item) => item.data.tt_category?.key === 'necessary').map((item) => item.data.tt_key)
    setAll(technicalPrivacyKeys, true)
  }

  private async acceptAll () : Promise<void> {
    const data = await this.fetchThirdPartyToolDetailsData()
    const allPrivacyKeys = data.map((item) => item.data.tt_key)
    setAll(allPrivacyKeys, true)
  }

  private async fetchThirdPartyToolDetailsData () : Promise<IThirdPartyToolDetailsData[]> {
    try {
      const { items } = await fsxaProxyApiRemote.fetchByFilter({
        filters: [
          {
            field: 'entityType',
            operator: ComparisonQueryOperatorEnum.EQUALS,
            value: 'third_party_tool_details',
          },
          {
            field: 'schema',
            operator: ComparisonQueryOperatorEnum.EQUALS,
            value: 'global_data',
          },
        ],
        locale: this.locale,
      })

      return (items as IThirdPartyToolDetailsData[] || []).filter((item) => item?.data?.tt_key)
    } catch (e) {
      return []
    }
  }

  private okay () : void {
    sessionStorage.setItem('show-cb', 'false')
    document.body.classList.remove('cb-visible')

    setTimeout(() => {
      document.body.classList.remove('show-cb')
    }, 500)
  }

  private setHeight (height : number) : void {
    document.documentElement.style.setProperty('--the-cookie-banner-height', `${height}px`)
  }

  private get showCookieBanner () : boolean {
    this.cookieBannerVisible = sessionStorage.getItem('show-cb') !== 'false'
      && localStorage.getItem('privacySettings') === null
    return this.cookieBannerVisible
  }

  @Watch('$store.state.PrivacySettings.settings', { deep: true, immediate: true })
  private settingsChanged () {
    if (Object.values(this.$store.state.PrivacySettings.settings).some((value) => value)) {
      document.body.classList.remove('cb-visible')
    }
  }

  @Watch('acceptAllLabel')
  @Watch('cookieBannerHeadline')
  @Watch('cookieBannerText', { deep: true, immediate: true })
  @Watch('cookieBannerLink', { deep: true, immediate: true })
  private cookieBannerTextChanged () : void {
    if (!process.client) return
    this.$nextTick().then(() => {
      window.dispatchEvent(new Event('resize'))
    })
  }
}
