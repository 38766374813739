
import {
  Component, Emit, Prop, ProvideReactive, Vue, Watch,
} from 'nuxt-property-decorator'
import { Dataset } from 'fsxa-api'
import IDropdownOption from '../../shared/general/interfaces/IDropdownOption'
import BaseGridLayout from '../layouts/BaseGridLayout.vue'
import BasePill from '../base/BasePill.vue'
import BaseIcon from '../base/BaseIcon.vue'
import BaseDropdown from '../base/form/BaseDropdown.vue'
import BaseCheckbox from '../base/form/BaseCheckbox.vue'
import BaseButton from '../base/BaseButton.vue'
import Filters from '../Filters.vue'
import { globalLabelAsString } from '../../shared/general/services/StoreService'
import { ILocation } from './LocationCard.vue'
import { IMapLocation } from './LocationMap.vue'
import IContactElement from '../../shared/general/interfaces/IContactElement'
import IFilterElement from '../../shared/general/interfaces/IFilterElement'
import getClassWithQuery from '../../shared/general/services/ContainerQueryService'

export enum ResultState {
  'NONE' = 0,
  FALSE = 1,
  TRUE = 2
}

export interface ILocationAddressObject {
  location : IContactElement[]
  detail : IContactElement[]
  address : ILocation
}

@Component({
  name: 'LocationSearch',
  components: {
    BaseGridLayout,
    BasePill,
    BaseIcon,
    BaseDropdown,
    BaseCheckbox,
    BaseButton,
    Filters,
    TopBar: () => import('./TopBar.vue'),
    LocationMap: () => import('./LocationMap.vue'),
    LocationCard: () => import('./LocationCard.vue'),
    BaseInput: () => import('../base/form/BaseInput.vue'),
    BaseHeadline: () => import('../base/BaseHeadline.vue'),
    LocationShadowMap: () => import('./LocationShadowMap.vue'),
    LocationDetailView: () => import('./LocationDetailView.vue'),
    ContactElements: () => import('../contact/ContactElements.vue'),
  },
})
export default class LocationSearch extends Vue {
  @ProvideReactive('wMax') wMax : boolean = false

  @Prop({ default: false }) commercialVehiclesFilter! : Boolean

  @Prop({ required: true }) locations! : IContactElement[][]

  @Prop({ required: true }) detailView! : IContactElement[][]

  @Prop({ required: true }) addresses! : ILocation[]

  @Prop({ required: true }) locationTypes! : Dataset[]

  @Prop({ default: ResultState.NONE }) hasResults! : ResultState

  @Prop() teaserImageUrl ?: string

  @Prop({ required: true }) googleMapsApiKey! : string

  @Prop() countryCode ?: string

  @Prop({ default: false }) contained! : boolean

  @Prop() preSelectedDropdownOption ?: IDropdownOption

  @Prop() locationTypeForCommercialVehicles ?: string

  private locationsAndAddresses : ILocationAddressObject[] = []

  private mobileSearchResultsOpen : boolean = false

  private mapLocations : IMapLocation[] = []

  // https://jira.dekra.com/browse/CMS-5714
  // private geolocation : Boolean = false

  private searchTerm : string = ''

  private latLng : google.maps.LatLng | false = false

  private searchState : 'default' | 'error' = 'default'

  private selectedDropdownOption : IDropdownOption = { id: '', label: '', value: '' }

  private commercialVehicle : boolean = false

  private activeId : string = ''

  private loading : Boolean = true

  private detailsViewOpen : Boolean = false

  private detailsViewContent : ILocationAddressObject | false = false

  private currentLocationId : string = ''

  private googleMapsApiLoaded : Boolean = false

  private geocoder ?: google.maps.Geocoder

  private geocoderResponse ?: google.maps.GeocoderResponse

  private isLocationBookmarked : boolean = false

  private selectedFilterOptions : IFilterElement[] = []

  $refs! : {
    searchResults ?: HTMLElement
    scrollElement ?: HTMLElement
    shadowElement ?: HTMLElement
    searchInput ?: Vue
  }

  // }
  async mounted () {
    const mapsLoaded = await this.$store.dispatch('GoogleMaps/init', {
      apiKey: this.googleMapsApiKey,
      version: 'weekly',
      libraries: ['places'],
    })
    if (!mapsLoaded) return
    const { google } = this.$store.state.GoogleMaps

    this.geocoder = new google.maps.Geocoder()
    this.googleMapsApiLoaded = true
    this.loading = false

    const options = {
      fields: ['address_components', 'adr_address', 'formatted_address'],
      strictBounds: false,
      componentRestrictions: {
        country: this.countryCode,
      },
    }

    const searchElement = this.$refs.searchInput?.$el.querySelector('input')
    if (!searchElement) return

    const autocomplete = new google.maps.places.Autocomplete(searchElement, options)

    autocomplete.addListener('place_changed', () => {
      const place = autocomplete.getPlace()
      if (!place) return

      this.searchTerm = place.formatted_address || place.name || ''
      this.$nextTick(() => {
        this.prepareSearch()
      })
    })

    // https://jira.dekra.com/browse/CMS-5714
    // this.geolocation = !!navigator.geolocation

    this.searchFromUrlParams()
  }

  private get resultsFilter () : string {
    return this.preSelectedDropdownOption?.label || this.selectedDropdownOption?.label || ''
  }

  private get postcodeCityLabel () : string {
    return globalLabelAsString('postcode_city_label')
  }

  private get enterSearchTermLabel () : string {
    return globalLabelAsString('enter_search_term_label')
  }

  private get typeOfLocationLabel () : string {
    return globalLabelAsString('type_of_location_label')
  }

  private get suitableForCommercialVehiclesLabel () : string {
    return globalLabelAsString('suitable_for_commercial_vehicles_label')
  }

  private get searchButtonLabel () : string {
    return globalLabelAsString('search_button_label')
  }

  private get resultsLabel () : string {
    return globalLabelAsString('results_label')
  }

  private get resultsForLabel () : string {
    return globalLabelAsString('results_for_label')
  }

  private get listLabel () : string {
    return globalLabelAsString('list_label')
  }

  private get mapLabel () : string {
    return globalLabelAsString('map_label')
  }

  private get headline () : string {
    return globalLabelAsString('search_location_label')
  }

  private get dropDownOptions () : IDropdownOption[] {
    return this.locationTypes.map((type : Dataset) => ({
      id: type.id,
      label: type?.data?.tt_name,
      value: type?.data?.tt_key,
    }))
  }

  private get filterOptions () : IFilterElement[] {
    return [{ listTitle: this.typeOfLocationLabel, options: this.dropDownOptions, multiSelect: false }]
  }

  private get resultsText () : string {
    return this.resultsFilter
      ? `${this.locations.length} ${this.resultsForLabel}`
      : `${this.locations.length} ${this.resultsLabel}`
  }

  private get isMobile () : boolean {
    return this.$store.getters['Viewport/isMobile']
  }

  private get height () : string {
    return this.contained ? 'h-full' : 'h-[617px]'
  }

  private get showCommercialVehicleFilter () : boolean {
    if (!this.locationTypeForCommercialVehicles?.length) return false

    if (this.selectedDropdownOption?.label?.length) {
      return this.locationTypeForCommercialVehicles.includes(this.selectedDropdownOption.label)
    }

    if (this.preSelectedDropdownOption?.label?.length) {
      return this.locationTypeForCommercialVehicles.includes(this.preSelectedDropdownOption.label)
    }

    return false
  }

  private async checkLocationIsBookmarked () : Promise<void> {
    const locationType = this.$store.state.ToolbarElements.locationSidebarType?.value
    const savedLocationId = await this.$store.dispatch('Locations/getSavedLocationId', locationType)
    this.isLocationBookmarked = savedLocationId === (this.detailsViewContent as ILocationAddressObject)?.address?.id
  }

  //   })
  private async checkShadow () : Promise<void> {
    await this.$nextTick()
    const { scrollElement, shadowElement } = this.$refs
    if (!scrollElement || !shadowElement) return

    shadowElement.classList[scrollElement.scrollTop > 0 ? 'add' : 'remove']('top-shadow')

    const isFullyScrolledDown = scrollElement.scrollHeight - scrollElement.clientHeight === scrollElement.scrollTop
     || scrollElement.scrollHeight > (scrollElement?.firstChild as HTMLElement)?.scrollHeight
    shadowElement.classList[isFullyScrolledDown ? 'remove' : 'add']('bottom-shadow')
  }

  //     this.geolocation = false
  private async prepareSearch () : Promise<void> {
    // reset shadowMap for next search
    this.latLng = false
    await this.$nextTick()

    if (!this.searchTerm) {
      this.searchState = 'error'
      return
    }

    this.loading = true
    try {
      this.geocoderResponse = await this.geocoder?.geocode(
        { address: this.searchTerm },
      )
    } catch (error) {
    // eslint-disable-next-line no-console
      console.log(error)
      this.loading = false
      return
    }

    const lat = this.geocoderResponse?.results?.[0]?.geometry?.location?.lat()
    const lng = this.geocoderResponse?.results?.[0]?.geometry?.location?.lng()

    if (lat && lng) {
      this.latLng = new google.maps.LatLng(lat, lng)
    }

    // we now wait for the shadow map to get loaded and figure out the bounds
    // as soon as the bounds are available, the shadow map automatically triggers the search
  }

  private getClassWithQuery (classes : string) : string {
    return getClassWithQuery(this.contained, classes)
  }

  private setFilter (filterElement : IFilterElement[]) : void {
    this.selectedFilterOptions = filterElement
    this.selectedDropdownOption = filterElement?.[0]?.options?.[0] || { id: '', label: '', value: '' }
    this.prepareSearch()
  }

  private resetFilter () : void {
    this.selectedFilterOptions = this.selectedFilterOptions.map((el) => ({ ...el, options: [] }))
    this.selectedDropdownOption = { id: '', label: '', value: '' }
    this.prepareSearch()
  }

  private openDetails (detail : ILocationAddressObject) : void {
    this.detailsViewContent = detail
    this.currentLocationId = detail.address.id || ''
    this.detailsViewOpen = true
  }

  private closeDetails () : void {
    this.detailsViewOpen = false
    this.currentLocationId = ''
  }

  private close () : void {
    if (this.contained) {
      this.$store.commit('Sidebar/set', false)
      return
    }

    this.mobileSearchResultsOpen = !this.mobileSearchResultsOpen
  }

  private back () : void {
    this.mobileSearchResultsOpen = !this.mobileSearchResultsOpen
  }

  private closeAll () : void {
    if (this.contained) {
      this.$store.commit('Sidebar/set', false)
      return
    }

    this.mobileSearchResultsOpen = false
    this.detailsViewOpen = false
  }

@Emit('saveBookmark')
  private saveBookmark (locationId : string) : string {
    return locationId
  }

/**
   * https://jira.dekra.com/browse/CMS-5714
   * This functionality is not port of the MVP, so is deactivated for now.
   * The second-suffix attribute should be added in the template
   * inside BaseInput after :suffix in order to activate the geolocation icon again.
   *
   * :second-suffix="geolocation ? {
   *    name: 'location-crosshairs',
   *    type: 'light',
   *    action: getUserLocation
   *  } : false" -->
   */

// https://jira.dekra.com/browse/CMS-5714
// getUserLocation () : void {
//   navigator.geolocation.getCurrentPosition(async (position) => {
//     try {
//       const bounds = await this.geocoder?.geocode(
//         { location: { lat: position.coords.latitude, lng: position.coords.longitude } },
//       )

//       if (!bounds) return

//       this.searchTerm = ''

//       this.$nextTick(() => {
//         this.searchTerm = bounds?.results?.[0]?.formatted_address

//         this.$nextTick(() => {
//           this.prepareSearch()
//         })
//       })
//     } catch (error) {
//       // eslint-disable-next-line no-console
//       console.log(error)
//       this.geolocation = false
//     }
//   }, (error) => {
//     // eslint-disable-next-line no-console
//     console.log(error)

private scrollTo (top : number, offset : number = 0, target : HTMLElement | Window | null = window) : void {
  setTimeout(() => {
    const topOffset = target === window ? top + window.pageYOffset - offset : top - offset
    target?.scrollTo({ top: topOffset, behavior: 'smooth' })
  }, 300)
}

private markerClick (id : string) : void {
  this.activeId = id
  this.mobileSearchResultsOpen = true

  // wait for the transition to end
  setTimeout(() => {
    const target = document.querySelector(`#location-${id}`) as HTMLElement
    if (!target) return

    this.scrollTo(
      target.offsetTop,
      80,
      target.parentElement,
    )
  }, 300)
}

private searchFromUrlParams () : void {
  const urlParams : URLSearchParams = new URLSearchParams(window.location.search);
  [...urlParams].forEach((param : [string, string]) => {
    const [key, value] = param
    if (key.toLowerCase() === 'searchfield') {
      this.searchTerm = value
    }

    if (key.toLowerCase() === 'locationtype') {
      const filterOption = this.filterOptions.map((lists) => lists.options?.filter((list) => list.label === value))
      if (filterOption?.[0]?.[0]) {
        // eslint-disable-next-line prefer-destructuring
        this.selectedDropdownOption = filterOption[0][0]
        this.selectedFilterOptions = this.filterOptions.map((item) => ({
          ...item,
          options: item.options?.filter(
            (option) => filterOption[0]?.some((f) => f.id === option.id && f.label === option.label && f.value === option.value),
          ),
        }))
      }
    }
  })

  if (this.searchTerm) this.prepareSearch()
}

private setUrlParams (locationType : IDropdownOption | undefined) : void {
  const urlParams : URLSearchParams = new URLSearchParams(window.location.search)
  urlParams.set('searchfield', this.searchTerm)
  if (locationType?.label) {
    urlParams.set('locationtype', locationType.label)
  } else {
    urlParams.delete('locationtype')
  }

  const pathname = urlParams.toString()
    ? `${window.location.pathname}?${urlParams.toString()}`
    : window.location.pathname

  window.history.replaceState({}, document.title, pathname + window.location.hash)
}

  @Emit('search')
private search (bounds : google.maps.LatLngBoundsLiteral) {
  this.activeId = ''
  const locationType = this.selectedDropdownOption?.value === '' ? undefined : this.selectedDropdownOption

  this.setUrlParams(locationType)

  return {
    commercialVehicle: this.commercialVehicle,
    locationType,
    bounds,
  }
}

  @Watch('addresses', { immediate: true, deep: true })
  private onAddressChange (addresses : ILocation[]) : void {
    this.loading = false

    this.mapLocations = addresses.map((address) => ({
      id: address.id,
      lat: address.latitude,
      lng: address.longitude,
    } as IMapLocation))

    this.locationsAndAddresses = this.locations
      .filter((location) => location && location[0] && location[0].data)
      .map((location, index) => ({
        location,
        detail: this.detailView[index],
        address: this.addresses[index],
      }))
      .sort((a, b) => (a.location[0].data as string).localeCompare(b.location[0].data as string))

    this.$nextTick(() => {
      this.checkShadow()
    })
  }

  @Watch('isMobile')
  private viewPortChanged () : void {
    this.$refs.searchResults?.classList.add('noTransition')
    setTimeout(() => {
      this.$refs.searchResults?.classList.remove('noTransition')
    }, 10)
  }

  @Watch('mobileSearchResultsOpen')
  private toggleBodyOverflow (newValue : boolean) : void {
    if (!this.isMobile) return
    document.body.classList[newValue ? 'add' : 'remove']('overflow-hidden')
  }
}
