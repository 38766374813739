
import {
  Component, Emit, Prop, Vue, Watch,
} from 'nuxt-property-decorator'
import { globalLabelAsString } from '../../../shared/general/services/StoreService'
import BaseButton from '../BaseButton.vue'
import BaseIcon from '../BaseIcon.vue'
import BaseInput, { TInputType } from './BaseInput.vue'

@Component({
  name: 'BaseSearchInput',
  components: {
    BaseButton,
    BaseIcon,
    BaseInput,
  },
})
export default class BaseSearchInput extends Vue {
  @Prop({ default: '' }) value! : string

  @Prop({ default: 'text' }) type! : TInputType

  @Prop({ default: false }) showLoadingAnimation! : boolean

  @Prop() label ?: string

  $refs! : {
    baseInput : BaseInput
  }

  private iValue : string = ''

  mounted () {
    this.iValue = this.value
  }

  private get searchFieldLabel () : string {
    return this.label ? this.label : globalLabelAsString('searchfield_label')
  }

  private get searchButtonLabel () : string {
    return globalLabelAsString('search_button_label')
  }

  private get isMobile () : boolean {
    return this.$store.getters['Viewport/isMobile']
  }

  @Watch('value')
  private onValueChange (val : string) : void {
    this.iValue = val
  }

  @Emit('input-value-changed')
  private inputValueChanged (value : string) : string {
    this.iValue = value
    return this.iValue
  }

  private search () : void {
    this.$emit('search', this.iValue)
  }

  public searchOrFocusInput () : void {
    if (this.iValue) {
      this.search()
    } else {
      this.focusInput()
    }
  }

  public focusInput () : void {
    this.$refs.baseInput?.focus()
  }
}
