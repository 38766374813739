
import {
  Component, Emit, Prop, Vue, Watch,
} from 'nuxt-property-decorator'
import { RichTextElement } from 'fsxa-api'
import IHeaderNavElement from '../../shared/general/interfaces/IHeaderNavElement'
import BaseButton from '../base/BaseButton.vue'
import BaseIconButton from '../base/BaseIconButton.vue'
import BaseNavigationLevel from '../base/BaseNavigationLevel.vue'
import { removeLastFromTree } from '../../shared/general/services/NavigationMenuService'
import { globalLabel, globalLabelAsString } from '../../shared/general/services/StoreService'
import isExternalURL from '../../shared/fsxa/services/ExternalLinkService'

@Component({
  name: 'TheBurgerMenu',
  components: {
    BaseButton,
    BaseIconButton,
    BaseNavigationLevel,
    GlobalLabelWrapper: () => import('../GlobalLabelWrapper.vue'),
  },
})
class TheBurgerMenu extends Vue {
  @Prop({ default: () => [] }) entries! : IHeaderNavElement[]

  private tree : IHeaderNavElement[] = []

  created () {
    this.onEntriesChange(this.entries)
  }

  private get contactUrl () : string | undefined {
    return this.$store.state.PageHeader.contactPage
  }

  private get contactLabel () : string {
    return globalLabelAsString('contact_label')
  }

  private get backLabel () : string | RichTextElement[] {
    return globalLabel('prev_date_label')
  }

  private navigate (navElement : IHeaderNavElement) {
    if (isExternalURL(navElement.path)) return

    this.$router.push(navElement.path)
    this.close()
  }

  private navigateOrOpenChild (navElement : IHeaderNavElement) {
    if (!navElement.children?.length) {
      this.navigate(navElement)
      return
    }

    const treeReload = [...this.tree]
    treeReload.push(navElement)
    this.tree = treeReload
  }

  private goBack () {
    this.tree = removeLastFromTree(this.tree)
  }

  private getCurrentHead (index : number) : IHeaderNavElement | undefined {
    return this.tree[index] || undefined
  }

  private getCurrentChildren (index : number) : IHeaderNavElement[] {
    return this.getCurrentHead(index)?.children || this.entries
  }

  @Emit('close')
  public close () {
    this.tree = [this.tree[0]]
    return true
  }

  @Watch('entries')
  private onEntriesChange (entries : IHeaderNavElement[]) {
    this.tree = [{ children: entries, key: '', path: '' }]
  }
}

export default TheBurgerMenu
