import { FSXABaseSection } from 'fsxa-pattern-library'
import { TTeaserVariant } from '../../../shared/general/types/TTeaserVariant'
import IOption from '../../../shared/general/interfaces/IOption'
import tailwindConfig from '../../../tailwind.config.js'

interface IPayload extends Record<string, any> {
  st_box_position ?: boolean
  st_column ?: IOption
}

export default abstract class BaseTeaserRc<Payload extends IPayload> extends FSXABaseSection<Payload> {
  protected teaserVariant : TTeaserVariant = 'vertical'

  protected show : boolean = false

  private watchMediaQuery = {
    lg: `(min-width: ${tailwindConfig.theme.screens.lg})`,
  }

  protected mounted () {
    // otherwise the variant will not always be updated correctly - $nextTick did not always work
    setTimeout(() => this.setTeaserVariant(!window?.matchMedia(this.watchMediaQuery.lg).matches), 100)

    window?.matchMedia(this.watchMediaQuery.lg).addEventListener('change', (event : MediaQueryListEvent) => {
      this.setTeaserVariant(!event.matches)
    })

    // otherwise the teasers will show empty on loading the page
    this.show = true
  }

  protected get anchorName () : string | undefined {
    return this.$store.getters['AnchorLinks/getAnchorNameForSection'](this.id)
  }

  protected get numberOfColumns () : number {
    return this.payload.st_column?.label === '2' ? 2 : 1
  }

  protected get boxPosition () : 'rc-top' | 'rc-bottom' {
    return this.payload.st_box_position ? 'rc-top' : 'rc-bottom'
  }

  private get variant () : TTeaserVariant {
    if (this.numberOfColumns !== 2) return 'vertical'
    return this.boxPosition
  }

  private setTeaserVariant (isMobile : boolean) : void {
    this.teaserVariant = isMobile ? 'vertical' : this.variant
  }
}
