
import {
  Component, Emit, Prop, Vue, Watch,
} from 'nuxt-property-decorator'
import { TIcon } from '../../../shared/general/types/TIcon'
import IInputIcon from '../../../shared/general/interfaces/IInputIcon'
import { globalLabelAsString } from '../../../shared/general/services/StoreService'

export type TInputType = 'button' | 'checkbox' | 'color' | 'date' | 'datetime-local' | 'email' | 'file' | 'hidden' | 'image'
  | 'month' | 'number' | 'password' | 'radio' | 'range' | 'reset' | 'search' | 'submit' | 'tel' | 'text'
  | 'time' | 'url' | 'week'

export type TInputState = 'default' | 'error' | 'valid' | 'disabled'

@Component({
  name: 'BaseInput',
  components: { BaseIcon: () => import('../BaseIcon.vue') },
})
export default class BaseInput extends Vue {
  @Prop({ default: '' }) value! : string

  @Prop({ default: 'text' }) type! : TInputType

  @Prop({ default: false }) mandatory! : boolean

  @Prop({ default: 'default' }) state! : TInputState

  @Prop({ required: true }) label! : string

  @Prop({ default: true }) floatingLabel! : boolean

  @Prop({ default: 'rounded' }) borderStyle! : 'rounded' | 'none' | 'rounded-left' | 'rounded-right'

  @Prop({ default: 'large' }) size! : 'small' | 'large'

  @Prop() message ?: string

  @Prop() prefix ?: IInputIcon

  @Prop() suffix ?: IInputIcon

  @Prop() secondSuffix ?: IInputIcon

  $refs! : {
    inputField : HTMLElement
  }

  private iValue : string = ''

  private hasFocus : boolean = false

  private isLeftIconMarginSet : boolean = false

  private focusStyle : string = ''

  private inputStyle = {
    default: 'border-gray-300',
    disabled: 'text-gray-300 border-gray-300 bg-gray-400',
    error: 'text-red border-red',
    valid: 'border-darkgreen',
  }

  private fixIconStyle = {
    default: 'text-gray-500',
    disabled: 'text-gray-300',
    error: 'text-gray-500',
    valid: 'text-gray-500',
  }

  private labelPeerStyle = {
    default: 'text-gray-500 peer-placeholder-shown:text-gray-500 peer-focus:text-gray-500',
    disabled: 'text-gray-300 peer-placeholder-shown:text-gray-300',
    error: 'peer-placeholder-shown:text-red peer-focus:text-red',
    valid: 'text-gray-500 peer-placeholder-shown:text-gray-500 peer-focus:text-gray-500',
  }

  private messageStyle = {
    default: 'text-gray-500',
    disabled: 'text-gray-500',
    error: 'text-red',
    valid: 'text-darkgreen',
  }

  private showStateIcon = {
    default: false,
    disabled: false,
    error: true,
    valid: true,
  }

  private stateIcon = {
    default: '',
    disabled: '',
    error: 'triangle-exclamation',
    valid: 'check',
  }

  private stateIconStyle = {
    default: '',
    disabled: '',
    error: 'text-red',
    valid: 'text-darkgreen',
  }

  private borderStyles = {
    none: 'border-none',
    rounded: 'border border-solid rounded-lg',
    'rounded-left': 'border border-solid rounded-l-lg',
    'rounded-right': 'border border-solid rounded-r-lg',
  }

  mounted () {
    this.iValue = this.value
  }

  private get peerLabelPosition () : string {
    if (this.isSizeSmall) {
      return 'top-1 peer-focus:top-1 peer-placeholder-shown:top-3.5'
    }
    return 'top-2.5 peer-focus:top-2.5 peer-placeholder-shown:top-5'
  }

  private get inputPaddings () : string {
    return this.isSizeLarge ? 'pt-[1.688rem] pb-[0.563rem]' : ''
  }

  private get addLeftIconMargin () : string {
    if (this.isLeftIconMarginSet) return 'mt-4'
    return this.isSizeLarge ? 'mt-0' : 'mt-0.5'
  }

  private get isSizeSmall () : boolean {
    return this.size === 'small'
  }

  private get isSizeLarge () : boolean {
    return this.size === 'large'
  }

  private get fieldLabel () : string {
    if (!this.label) return ''
    return this.mandatory ? `${this.label}*` : this.label
  }

  private get prefixIcon () : string | undefined {
    return this.prefix?.name
  }

  private get prefixType () : TIcon {
    return this.prefix?.type || 'light'
  }

  private get sizeStyle () : string {
    return this.isSizeLarge ? 'mt-0 text-base h-[3.875rem]' : 'mt-0 text-sm h-14'
  }

  private get messageMarginStyle () : string {
    if (this.message) return 'mt-0'
    if (!this.message && this.isSizeSmall) return 'mt-0'
    if (!this.message) return 'mb-0'

    return ''
  }

  private get suffixIcon () : string | undefined {
    return this.suffix?.name
  }

  private get suffixType () : TIcon {
    return this.suffix?.type || 'light'
  }

  private get secondSuffixIcon () : string | undefined {
    return this.secondSuffix?.name
  }

  private get secondSuffixType () : TIcon {
    return this.secondSuffix?.type || 'light'
  }

  private get isInStateDisabled () : boolean {
    return this.state === 'disabled'
  }

  private get isInStateError () : boolean {
    return this.state === 'error'
  }

  private get searchLabel () : string {
    return globalLabelAsString('search_button_label')
  }

  private get clear () : string {
    return globalLabelAsString('clear_all_label')
  }

  private isClickable (inputIcon ?: IInputIcon) : boolean {
    return !!inputIcon?.action
  }

  private fixClickableStyle (inputIcon ?: IInputIcon) : string {
    return this.isClickable(inputIcon) ? 'cursor-pointer hover:text-black' : 'cursor-default'
  }

  @Emit('suffix-clicked')
  private suffixClicked () : void {
    this.suffix?.action?.()
  }

  @Emit('prefix-clicked')
  private prefixClicked () : void {
    this.prefix?.action?.()
  }

  @Emit('enter-pressed')
  private onEnter () : boolean {
    return true
  }

  @Emit('second-suffix-clicked')
  private secondSuffixClicked () : void {
    this.secondSuffix?.action?.()
  }

  @Watch('value')
  private eonValueChange (val : string) : void {
    this.iValue = val
  }

  @Watch('iValue')
  @Emit('change')
  private onTypeValueChange (val : string) : string {
    return val
  }

  /**
   * Update style when state changes.
   * This happens for example when a validation does change the state from error to valid.
   */
  @Watch('state')
  private onStateChange () : void {
    if (this.hasFocus) {
      this.onFocusin()
    } else {
      this.onFocusout()
    }
  }

  private onFocusin () : void {
    this.hasFocus = true
    if (this.isInStateDisabled || this.borderStyle === 'none') return
    if (this.isInStateError) {
      this.focusStyle = '!border-red outline outline-4 outline-red-300'
    }
    if (this.label) this.isLeftIconMarginSet = true
  }

  private onFocusout () : void {
    this.hasFocus = false
    this.focusStyle = ''
    if (this.label && !this.iValue) this.isLeftIconMarginSet = false
  }

  private clearInput () : void {
    this.iValue = ''
  }

  public focus () : void {
    this.$refs.inputField?.focus()
    this.onFocusin()
  }
}
