
import { FSXABaseComponent, FSXARenderContentElement } from 'fsxa-pattern-library'
import { Section } from 'fsxa-api'
import { Component } from 'nuxt-property-decorator'
import BaseGridLayout from '../layouts/BaseGridLayout.vue'
import { trackPI } from '../../shared/general/tracking/TrackingService'

@Component({
  name: 'Page404',
  components: {
    BaseGridLayout,
    ContentElement: FSXARenderContentElement,
    BaseHeadline: () => import('../base/BaseHeadline.vue'),
  },
})
export default class Page404 extends FSXABaseComponent {
  private show : boolean = false

  created () {
    if (this.$isServer && this.$nuxt.context.res) {
      this.$nuxt.context.res.statusCode = 404
    }

    trackPI(this.pageTitle)
  }

  mounted () {
    setTimeout(() => {
      this.show = true
    })
  }

  private get headline () : string {
    return this.globalSettings?.data?.ps_country_project_settings?.pt_404_error_page_headline || ''
  }

  private get elements () : Section[] {
    return this.globalSettings?.data?.ps_country_project_settings?.pt_404_error_page_content || []
  }

  private get pageTitle () : string {
    return this.globalSettings?.data?.ps_country_project_settings?.pt_404_error_page_browser_title || ''
  }

  head () {
    return {
      title: this.pageTitle,
    }
  }
}
